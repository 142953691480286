@import "../../main.module.scss";

.Markdown {
    width: 100%;
    color: $color-default;

    strong, a {
        color: $color-accent;
    }

    p {
        text-align: justify;
    }
}