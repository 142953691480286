@import "../../main.module.scss";

.Gig {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 16px;
    height: 100%;
    color: $color-default;
}