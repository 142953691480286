@import "../../main.module.scss";

.Members {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    .MemberList {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        grid-gap: 1em;
        background: $color-gray-2;
        padding: 19px;
        min-width: 200px;
        border-radius: 5px;

        .Member {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 4px;

            .Avatar {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                gap: 4px;

                .Image {
                    width: 200px;
                    height: 150px;
                    position: relative;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    background: rgba(0, 0, 0, 0.75);
                    border-radius: 5px;

                    img {
                        height: 100%;
                        border-radius: 5px;
                        -webkit-filter: grayscale(100%);
                        /* Safari 6.0 - 9.0 */
                        filter: grayscale(100%);
                        
                    }
                }

                .Banner {
                    position: absolute;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    height: 33.33%;
                    width: 100%;
                    bottom: 0;
                    color: $color-default;
                    background: rgba(0, 0, 0, 0.75);
                    border-radius: 0 0 5px 5px;

                    /*&:hover {
                        justify-content: start;
                        height: 100%;
                    }*/

                    .Text {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        opacity: 1;
                    }
                }
            }
        }
    }
}