@import "../../main.module.scss";

.Modal {
    &Overlay {
        z-index: 9999;
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.7);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &Box {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background: $color-gray-2;
        width: 95%;
        height: 95%;

        &Header {
            height: 5%;
            padding: 1rem;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            border-bottom: 1px solid $color-default;
            color: $color-default;

            &Title {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
            }

            &Close {
                position: relative;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                font-weight: bold;
                cursor: pointer;
                aspect-ratio: 1/1;

                &:before,
                &:after {
                    position: absolute;
                    left: 50%;
                    content: " ";
                    height: 100%;
                    width: 1px;
                    background-color: $color-default;
                }

                &:before {
                    transform: rotate(45deg);
                }

                &:after {
                    transform: rotate(-45deg);
                }
            }
        }

        &Content {
            height: 95%;
            padding: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            overflow: auto;
        }
    }
}
