@import "../../main.module.scss";

.Tabs {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    outline: 1px solid $color-default;
    height: 100%;

    &Bar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 1px;

        &Tab {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            cursor: pointer;
            width: 100%;
            padding: 1em;
            outline: 1px solid $color-default;

            &Active {
                color: $color-accent;
                background: $color-gray-1;
            }

            &Title {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }

    &Content {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: flex-start;
        overflow: auto;
        padding: 1em;
    }
}