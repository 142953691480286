@import "../../main.module.scss";

.GigMain {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;

    &Grid {
        display: inline-grid;
        grid-template-columns: minmax(auto, 25%) minmax(auto, 75%);

        a {
            color: $color-accent;
        }
    }
}