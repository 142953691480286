@import "../../main.module.scss";

.GigRunningOrder {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    overflow: auto;

    &Grid {
        display: inline-grid;
        grid-template-columns: auto auto auto;
        grid-gap: 0 10px;
        align-items: center;
        justify-content: center;

        &Indicator {
            border: solid $color-default;
            border-width: 0 3px 3px 0;
            display: inline-block;
            padding: 3px;
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
        }

        &Dot {
            border: 5px solid $color-gray-1;
            border-radius: 500%;
            background: $color-gray-3;
            height: 15px;
            aspect-ratio: 1/1;
            transition: all 500ms ease-in-out;

            &Act {
                background: $color-accent;
            }

            &Break {
                background: $color-gray-2;
            }
        }

        &Title {
            color: $color-default;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &Duration {
            color: $color-default;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &Line {
            min-height: 38px;
            border-left: 1px dashed $color-default;
            margin: 0 50%;
        }
    }
}