@import "../../main.module.scss";

.GigGrid {
    display: inline-grid;
    grid-template-columns: minmax(auto, auto) minmax(auto, auto) minmax(auto, auto);
    gap: 0.5em;
    width: 100%;

    &Title {
        cursor: pointer;
        color: $color-accent;
        text-decoration: underline;
    }

    &Item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    &ItemPast {
        color: $color-default-faded;
    }

    &Header {
        font-weight: bold;
    }
}