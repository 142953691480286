@import "../../main.module.scss";

.Social {
    position: fixed;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 999;


    &.Visible {
        right: 0;
        transition: right 0.75s ease;
    }

    &.Hidden {
        right: -48px;
        transition: right 0.75s ease 0.5s;
    }

    .SocialContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .PopIn {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .PopInButton {
                border-radius: 5px 0px 0px 5px;
                height: 64px;
                width: 32px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                background: $color-gray-2;
                color: $color-default;
                cursor: pointer;

                &:hover {
                    color: $color-accent;
                }

                svg {
                    width: 32px;
                }
            }
        }

        .SocialMenu {
            border-radius: 5px 0px 0px 5px;
            background: $color-gray-2;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 4px;
            padding: 8px;

            .SocialIcon {
                &.Visible {
                    opacity: 1;
                    transition: opacity .75s ease .5s;
                }

                &.Hidden {
                    opacity: 0;
                    transition: opacity .75s ease;
                }
            }
        }
    }
}