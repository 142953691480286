@import "../../main.module.scss";

.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus {
    border: 4px solid $color-accent !important;
}

.image-gallery-icon:hover {
    color: $color-accent !important; 
}

.image-gallery-thumbnail:hover {
    outline: none;
    border: 4px solid $color-accent !important;
}