@import "../../main.module.scss";

.Footer {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    background: $color-gray-2;

    .Container {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
}